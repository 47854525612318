<template>
	<div class="containerx">
		<!--  环境检测  -->
		<div class="nyjc">
			<div class="title">设备详细信息</div>
			<div class="row">
				<div class="item">设备名称：{{sb_obj.name}}</div>
				<div class="item">设备编号：{{sb_obj.serialNumber}}</div>
        <div class="item">物联编号：{{sb_obj.monitorSerialNumber}}</div>
				<div class="item">项目：{{sb_obj.projectName}}</div>
        <div class="item" v-for="(item,index) in paraList" :key="item">{{item}}</div>
			</div>
			<div class="row">

				<!-- <div v-for="(item, index) in sensorList" :key="item.index"  class="item">
					{{item.name}} <span v-if="item.orderNum>1">{{item.orderNum}}</span>：
					<span v-if="item.currentValue!=5001">{{item.currentValue}}</span>
					<span v-if="item.currentValue==5001">00</span>
					{{item.unit}}
				</div> -->
				<div v-if="sensorList.length==0" class="notice_msg">设备还未上传数据</div>
			</div>

			<div class="title" style="margin-top: 1rem;">历史数据</div>
			<!-- 日期选择器 -->
			<div class="date-select">
				<el-date-picker @change="dateChange" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" v-model="date"
					type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" :picker-options="pickerOptions">
				</el-date-picker>
			</div>
			<!--   图表   -->
			<div id="myChart" style="width: 100%;height: 42.5rem"></div>
		</div>
		<!-- 报警设备列表 -->
		<div class="table">
			<div class="tr bg">
				<div class="td1">
					<p>序号</p>
				</div>
				<div class="td2">
					<p>设备名称</p>
				</div>
				<div class="td3 td">
					<p>设备位置</p>
				</div>
				<div class="td4 td">
					<p>异常原因</p>
				</div>
				<div class="td5 td">
					<p>结束时间</p>
				</div>
				<div class="td6 td">
					<p>告警时长</p>
				</div>
				<div class="td7 td">
					<p>结束时间</p>
				</div>
			</div>
			<div @click="handleTable(item)" style="cursor: pointer;" class="tr" v-for="(item, index) in tableData"
				:class="index % 2 == 0?'bg1':'bg2'">
				<div class="td1">
					<p>{{ index+1 }}</p>
				</div>
				<div class="td2">
					<div class="z1">
						<div class="item-show">{{item.name}}</div>
						<div class="hidden-text">{{item.name}}</div>
					</div>
				</div>
				<div class="td3 td">
					<div class="z1">
						<div class="item-show">{{item.address}}</div>
						<div class="hidden-text">{{item.address}}</div>
					</div>
				</div>
				<div class="td4 td">
					<p>{{item.abnormalCause}}</p>
				</div>
				<div class="td5 td">
					<p>{{item.startTime}}</p>
				</div>
				<div class="td7 td">
					<p>{{item.alarmDuration}}分钟</p>
				</div>
				<div class="td7 td">
					<p>{{item.endTime}}</p>
				</div>
			</div>
		</div>
		<div class="pagination">
			<el-pagination style="color: white" background :page-sizes="[10, 15, 20]" @current-change="currentChange"
				@size-change="sizeChange" :page-size="facility_size" :current-page.sync="facility_page"
				layout="total, sizes, prev, pager, next, jumper" :total="facility_total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		name: 'index1',
		props: {
			projectId: {
				type: Number,
				default: null
			},
			projects: {
				type: Number,
				default: null
			},
			sbId: {
				type: Number,
				default: null
			},
			hjjc_current: {
				type: Number,
				default: null
			},

		},
		data() {
			return {
				sensorList:[],
				nData_obj1: {},
				nData_obj2: {},
				sb_obj: {},
        paraList:[],
				edit_p_show: false,
				edit_p_obj: {
					"address": "",
					"id": 0,
					"manufactor": "",
					"name": "",
					"parameter": "",
					"productionData": "",
					"serialNumber": "",
					"specifications": "",
					"voltageLevel": ""
				},
				tableData: [],
				sssj_current: 0,
				hjjc_arr: ['环境设备', '能源设备'],
				facility_type: 1,
				facility_page: 1,
				facility_size: 10,
				facility_total: 0,
				// 设备id
				facilityId: 1,
				sssj_obj: {},
				type: 0, // 环境设备0能源设备1
				// 图标横纵坐标
				xData: [],
				yData: [],
				// 日期
				beginTime: '',
				endTime: '',
				date: ['', ''],
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
		methods: {
			// 设备状态：0-在线 1-离线 2-异常
			rtState(num) {
				switch (num) {
					case 0:
						return '在线'
						break;
					case 1:
						return '离线'
						break;
					default:
						return '异常'
				}
			},
			// 根据设备id查询设备详情
			getSbxq() {
				this.$newGet('/equipment/info/' + this.sbId).then(res => {
					this.sb_obj = res.data
          this.paraList  = res.data.parameter.split(",");
				})
			},
			// 根据设备id查询能源 环境设备最新的数据
			getNewData() {
				if (this.hjjc_current == 0) {
					this.$newGet('/online/monitoring/newest/environment/' + this.sbId).then(res => {
						if (res.data) {
							this.sensorList = res.data
						} else {
							this.$message({
								message: '设备暂未提交数据',
								type: 'success'
							})
						}
					})
				} else {
					this.$newGet('/online/monitoring/newest/energy/' + this.sbId).then(res => {
						console.log(res, '能源')
						if (res.data) {
							this.sensorList = res.data
						} else {
							this.$message({
								message: '设备暂未提交数据',
								type: 'success'
							})
						}
					})
				}
			},
			// 点击修改设备
			editP(item) {
				this.edit_p_obj = JSON.parse(JSON.stringify(item))
				this.edit_p_show = true
			},
			// 修改设备
			confirmEditP() {
				this.$newPost('/equipment/update', this.edit_p_obj).then(res => {
					if (res.code == 2000) {
						this.$message({
							message: res.message,
							type: 'success'
						});
						this.edit_p_show = false
						this.getFacility()
					} else {
						this.$message({
							message: res.message,
							type: 'warning'
						});
					}
				})
			},
			handleTable(item) {
				console.log(item)
				return
				this.facilityId = item.id
				this.getNewData()
			},
			// 设备状态：0-在线 1-离线 2-异常
			rtState(num) {
				switch (num) {
					case 0:
						return '在线'
						break;
					case 1:
						return '离线'
						break;
					default:
						return '异常'
				}
			},
			hjjc(item, index) {
				console.log(item, index)
				this.hjjc_current = index
				this.type = index
				this.getFacility()
			},
			// 根据设备id分页查询告警记录列表
			getGgList() {
				var obj = {
					page: this.facility_page,
					size: this.facility_size,
					"equipmentId": this.sbId,
				}
				this.$newPost('/equipment/warning/record', obj).then(res => {
					console.log(res, '告警列表')
					this.tableData = res.data.records
					this.facility_total = res.data.total
					if (res.data.records.length > 0) {
						// this.facilityId = res.data.records[0].id
					}
					// this.getNewData()
				})
			},
			currentChange(e) {
				console.log(e)
				this.facility_page = e
				this.getGgList()
			},
			sizeChange(e) {
				this.facility_size = e
				this.getGgList()
			},
			// 图表
			mycharts() {
				var chartDom = document.getElementById('myChart');
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					// 提示框
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#2BDEC9'
							}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.xData
					},
					yAxis: {
						type: 'value'
					},
					series: this.yData
				};

				option && myChart.setOption(option);
			},
			// 获取数据和图表
			getCharts() {
				var obj = {
					"beginTime": this.beginTime,
					"endTime": this.endTime,
					"equipmentId": this.sbId
				}
				if (this.hjjc_current == 0) {
					// 环境
					var url = '/equipment/environment/data'
					this.$newPost(url, obj).then(res => {
						
						if (res.data.length > 0) {
							this.xData = []
							this.yData = []
							var y1 = {
								name: '温度',
								type: 'line',
								stack: 'Total',
								data: []
							}
							var y2 = {
								name: '湿度',
								type: 'line',
								stack: 'Total',
								data: []
							}
							var y3 = {
								name: '气体',
								type: 'line',
								stack: 'Total',
								data: []
							}
							var y4 = {
								name: '震动',
								type: 'line',
								stack: 'Total',
								data: []
							}
							var y5 = {
								name: '水位',
								type: 'line',
								stack: 'Total',
								data: []
							}
							var y6 = {
								name: '故障状态',
								type: 'line',
								stack: 'Total',
								data: []
							}
							res.data.forEach((item, index) => {
								this.xData.push(item.gmtCreate)
								y1.data.push(item.temperature)
								y2.data.push(item.humidity)
								y3.data.push(item.gas)
								y4.data.push(item.vibration)
								y5.data.push(item.waterLevel)
								y6.data.push(item.fault)
							})
							
							setTimeout(() => {
								this.yData = [y1, y2, y3, y4, y5,y6]
								this.mycharts()
							})
						} else {
							this.xData = []
							this.yData = []
							this.mycharts()
						}
					})
				} else {
					var url = '/equipment/energy/data'
					this.$newPost(url, obj).then(res => {
						if (res.data.length > 0) {
							this.xData = []
							this.yData = []
							var y1 = {
								name: 'A相电压',
								type: 'line',
								stack: 'Total',
								data: []
							}
              var y2 = {
              	name: 'B相电压',
              	type: 'line',
              	stack: 'Total',
              	data: []
              }
              var y3 = {
              	name: 'C相电压',
              	type: 'line',
              	stack: 'Total',
              	data: []
              }
							var y4 = {
								name: 'A相电流',
								type: 'line',
								stack: 'Total',
								data: []
							}
              var y5 = {
              	name: 'B相电流',
              	type: 'line',
              	stack: 'Total',
              	data: []
              }
              var y6 = {
              	name: 'C相电流',
              	type: 'line',
              	stack: 'Total',
              	data: []
              }
							var y7 = {
								name: '净有功电度',
								type: 'line',
								stack: 'Total',
								data: []
							}
              var y8 = {
              	name: '净有无功电度',
              	type: 'line',
              	stack: 'Total',
              	data: []
              }
							var y9 = {
								name: '系统功率因数',
								type: 'line',
								stack: 'Total',
								data: []
							}
              var y10 = {
              	name: '系统有功功率',
              	type: 'line',
              	stack: 'Total',
              	data: []
              }
              var y11 = {
              	name: '系统无功功率',
              	type: 'line',
              	stack: 'Total',
              	data: []
              }
							res.data.forEach((item, index) => {
								this.xData.push(item.gmtCreate)
								y1.data.push(item.ua)
                y2.data.push(item.ub)
                y3.data.push(item.uc)
                y4.data.push(item.ia)
                y5.data.push(item.ib)
                y6.data.push(item.ic)
                y7.data.push(item.epNet)
                y8.data.push(item.eqNet)
								y9.data.push(item.pf)
								y10.data.push(item.psum)
								y11.data.push(item.qsum)
							})
							console.log(this.xData)
							setTimeout(() => {
								this.yData = [y1, y2, y3, y4,y5,y6,y7,y8,y9,y10,y11]
								this.mycharts()
							})
						} else {
							this.xData = []
							this.yData = []
							this.mycharts()
						}
					})

				}


			},
			dateChange(e) {
				this.beginTime = e[0]
				this.endTime = e[1]
				this.getCharts()
			},
		},
		mounted() {
			this.getNewData()
			this.getSbxq()
			this.getCharts()
			this.getGgList()
			// this.getCharts()
			// this.getFacility()
		},
		watch: {
			sssj_current(val) {
				// this.getCharts()
			},
		},

	}
</script>

<style lang="scss" scoped>
	.bg {
		background-color: rgba(43, 201, 222, 0.6);
	}

	.bg1 {
		background-color: rgba(162, 162, 162, 0.1);
	}

	.bg2 {
		background: rgba(14, 242, 242, 0.25);
	}

	.containerx {
		color: #ffffff;
		overflow: auto;
	}

	.btngrp {
		margin-left: 1rem;
		margin-top: 1rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.btn1 {
			width: 15rem;
			height: 8.4rem;
			line-height: 8.4rem;
			text-align: center;
			background: rgba(43, 201, 222, 0.6);
			font-size: 1.8rem;
			margin-right: 3rem;
			cursor: pointer;
			margin-bottom: 2rem;
		}

		.btn2 {
			width: 15rem;
			height: 8.4rem;
			line-height: 8.4rem;
			text-align: center;
			background: rgba(43, 222, 201, 0.6);
			font-size: 1.8rem;
			margin-right: 3rem;
			cursor: pointer;
			margin-bottom: 2rem;
		}
	}


	.sblb {
		background-color: #192534;
		margin: 2rem 0;
	}

	.nyjc {
		background-color: #192534;


		.sssj {
			display: flex;
			align-items: center;
			margin-left: 1.2rem;

			.item {
				display: flex;
				margin-right: 3rem;
				cursor: pointer;
			}

			.img2 {
				width: 20rem;
				height: 3.6rem;
				margin-left: 1.2rem;
			}

			.img1 {
				width: 6.4rem;
				height: 6.6rem;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 0.2rem solid rgba(43, 222, 201, 0.6);
				border-radius: 0.6rem;

				img {
					width: 4rem;
					height: 4rem;
				}
			}

			.t1 {
				position: absolute;
				top: 1.05rem;
				right: 15.8rem;
				font-size: 1.6rem;
			}

			.t2 {
				position: absolute;
				top: 1.05rem;
				right: 1.5rem;
				font-size: 1.6rem;
			}
		}
	}

	.title {
		font-size: 2.4rem;
		font-weight: 500;
		margin-left: 2rem;
		//margin-top: 2rem;
		height: 5rem;
		line-height: 5rem;
		color: #2BC9DE;
	}

	.table {
		width: 154.6rem;
		height: 60rem;
		overflow: auto;
		margin: 0 auto;

		.tr {
			display: flex;
			// margin-top: 1rem;
			padding: .5rem 0;

			.td1 {
				font-size: 1.4rem;
				width: 6.6rem;
				height: 4.2rem;
				margin-right: 0.4rem;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.td2 {
				font-size: 1.4rem;
				width: 20rem;
				height: 4.2rem;
				display: flex;
				align-items: center;
				padding-left: 2rem;
				margin-right: 0.4rem;
			}

			.td {
				font-size: 1.4rem;
				height: 4.2rem;
				display: flex;
				align-items: center;
				padding-left: 2rem;
				margin-right: 0.4rem;
			}

			.td3 {
				width: 30.4rem;
			}

			.td4 {
				width: 30.8rem;
			}

			.td5 {
				width: 20rem;
			}

			.td6 {
				width: 16rem;
			}

			.td7 {
				width: 15rem;
			}

			.td8 {
				width: 16.2rem;
			}

			.td9 {
				width: 16rem;
			}



		}

		.tr:nth-child(n+2):hover {
			background-color: rgba(117, 117, 117, 0.4);
		}
	}

	.pagination {
		display: flex;
		justify-content: flex-end;
		margin-right: 1rem;
	}

	.date-select {
		margin-left: 15rem;
		margin-top: 4rem;
		margin-bottom: 2rem;

		/deep/ .el-input__inner {
			border-color: #2BDEC9;
			background-color: unset;
			color: #2BDEC9;

			.el-range-separator {
				color: #2BDEC9;
			}

			.el-input__icon {
				color: #2BDEC9;
			}

			input {
				background-color: unset;
				color: #2BDEC9;
			}
		}
	}

	.z1 {
		width: 100%;
		height: 4.2rem;

		.item-show {
			height: 4.2rem;
			line-height: 4.2rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.hidden-text {
			font-size: 1.7rem;
			position: relative;

			// width: 25rem;
			line-height: 3.0rem;
			z-index: -2;
			top: -4.2rem;
			padding: 8px 8px 8px 8px;
			border-radius: .2rem;
		}

	}
	.z1:hover .hidden-text {
		// display: show;
		background-color: rgba(8, 157, 157, 1);
		z-index: 999;
	}

	.row {
		display: flex;
		flex-wrap: wrap;
		width: 98%;
		margin: 0 auto;

		.item {
			width: 46.6rem;
			height: 5rem;
			line-height: 5rem;
			font-size: 2rem;
			color: #ffffff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

		}
	}
	.notice_msg {
		display: flex;
		font-size: 1.8rem;
		margin-top: 5rem;
		width: 100%;
		justify-content: center;
	}
</style>
